<template>
  <div class="after-sale-refund-page" ref="afterSaleRefund">
    <div ref="explain" class="explain">
      <header><i class="el-icon-warning"></i> 数据说明：</header>
      <ol>
        <li>
          售后退款支持对已检票、已退票、拒绝乘车的订单进行退款处理（售后时长为48小时，即今天和昨天的支持售后退款）
        </li>
      </ol>
    </div>
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane label="售后退款" name="first">
        <div ref="form" class="form-area">
          <el-form
            :inline="true"
            :model="form"
            class="demo-form-inline"
            label-position="left"
            label-width="85px"
          >
            <el-form-item label="订单编号：">
              <el-input
                v-model.trim="form.orderId"
                placeholder="请输入订单编号"
              ></el-input>
            </el-form-item>
            <el-form-item label="下单人：">
              <el-input
                v-model.trim="form.passengerPhone"
                placeholder="请输入下单人"
              ></el-input>
            </el-form-item>
            <el-form-item label="联系电话：">
              <el-input
                v-model.trim="form.orderPhone"
                placeholder="请输入联系人电话"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="small" @click="onSearch"
                >查询</el-button
              >
            </el-form-item>
          </el-form>
        </div>
        <Table
          :table-data="tableData"
          :title-name="titleName"
          :table-height="TableHeight"
          operation
          :col-class-type="true"
          :empty-text="emptyText"
          operation-width="160"
        >
          <template slot-scope="scope">
            <div>
              <el-button
                v-if="
                  listFind('售后退款') && scope.scopeRow.purchaseMethod != 3
                "
                type="text"
                size="small"
                sort="primary"
                @click="RefundTicket(scope.scopeRow)"
                >售后退款</el-button
              >
            </div>
          </template>
        </Table>
        <div class="pagination">
          <el-pagination
            background
            layout="total,sizes,prev, pager, next,jumper"
            :total="total"
            :page-size="form.pageSize"
            :current-page="form.currentPage"
            @size-change="onSizeChange"
            @current-change="onCurrentChange"
          ></el-pagination>
        </div>
      </el-tab-pane>
      <el-tab-pane label="售后退款明细" name="second">
        <div ref="form2" class="form-area">
          <el-form
            :inline="true"
            :model="form2"
            class="demo-form-inline"
            label-position="left"
            label-width="85px"
          >
            <el-form-item label="退款时间：" class="code-time-range">
              <el-date-picker
                v-model="value"
                type="datetimerange"
                range-separator="至"
                value-format="yyyy-MM-dd HH:mm:ss"
                format="yyyy-MM-dd HH:mm:ss"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :default-time="['00:00:00', '23:59:59']"
                @change="selectTime"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="订单编号：">
              <el-input
                v-model.trim="form2.orderId"
                placeholder="请输入订单编号"
              ></el-input>
            </el-form-item>
            <el-form-item label="下单人：">
              <el-input
                v-model.trim="form2.passengerName"
                placeholder="请输入下单人"
              ></el-input>
            </el-form-item>
            <el-form-item label="联系电话：">
              <el-input
                v-model.trim="form2.passengerPhone"
                placeholder="请输入联系人电话"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="small" @click="onSearch2"
                >查询</el-button
              >
              <el-button type="primary" size="small" @click="onExport"
                >导出</el-button
              >
            </el-form-item>
          </el-form>
        </div>
        <Table
          :table-data="tableData2"
          :title-name="titleName2"
          :table-height="TableHeight2"
          :col-class-type="true"
        />
        <div class="pagination">
          <el-pagination
            background
            layout="total,sizes,prev, pager, next,jumper"
            :total="total2"
            :page-size="form2.pageSize"
            :current-page="form2.currentPage"
            @size-change="onSizeChange"
            @current-change="onCurrentChange"
          ></el-pagination>
        </div>
      </el-tab-pane>
    </el-tabs>
    <el-dialog
      v-if="charterPopupShow"
      :visible.sync="charterPopupShow"
      width="900px"
      title="退票"
      @close="charterPopupClosePopupFun"
    >
      <div style="margin: 0 40px">
        <Table
          :table-data="tableDataInner"
          :title-name="titleNameInner"
          :type-checkbox="true"
          :canSelect="true"
          :isSingleChecked="true"
          @closeFun="closeFun"
          @select-all="select"
        />
        <div class="feeBox">
          <div>
            <p>可退金额(元)</p>
            <el-input v-model="refundTotal" disabled />
            <p></p>
            <label v-if="openTicket"
              >该订单已开票，可通过【电子发票管理】冲红后再进行退款</label
            >
          </div>
          <div>
            <p>售后退款金额(元)</p>
            <el-input v-model="refundableAmountPrices" />
          </div>
        </div>
        <div class="reasonBox">
          <p>原因</p>
          <el-input
            v-model.trim="ReasonForRefund"
            class="textareaInputs"
            type="textarea"
            placeholder="填写原因时请注意，该信息将以短信的形式发送给用户。"
          ></el-input>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="charterPopupClosePopupFun">取消</el-button>
        <el-button
          type="primary"
          :disabled="loading"
          @click="charterPopupConfirmFun"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  queryScheduleHistoryList,
  referOrderDetialAPI,
  refundedChildOrder,
  queryAfterSalesRefundDetailPage,
  exportAfterSalesRefundDetail,
  isAlreadyInvoicedAPI,
} from "@/api/lib/api.js";
import decryptDes from "@/tools/decryptDes.js";
import { formatDay } from "@/common/utils/index.js";
export default {
  data() {
    return {
      TableHeight: 0,
      TableHeight2: 0,
      activeName: "first",
      form: {
        orderId: "",
        passengerPhone: "",
        orderPhone: "",
        pageSize: 10,
        currentPage: 1,
        orderStatus: [60,70, 80, 120], //仅筛选已检票、已退票、拒绝乘车的订单+已完成70
        purchaseMethods: [0, 1, 2,4],//0-乘客购票/1-后台二维码购票/2-快客码购票/4-电话预留
      },
      value: [],
      form2: {
        orderId: "",
        passengerPhone: "",
        passengerName: "",
        pageSize: 10,
        currentPage: 1,
        startRefundDate: "",
        endRefundDate: "",
      },
      tableData: [],
      titleName: [
        {
          title: "订单编号",
          props: "orderId",
          width: "200",
        },
        {
          title: "下单人",
          props: "passengerPhone",
        },
        {
          title: "联系电话",
          width: 120,
          props: "orderPhone",
        },
        {
          title: "乘客数量",
          props: "passengerCount",
        },
        {
          title: "有效乘车人数",
          width: 120,
          props: "validPassengersNum",
        },
        {
          title: "票价",
          props: "ticketPricesPer",
        },
        {
          title: "服务费",
          props: "servicePricesPer",
        },
        {
          title: "应付金额",
          props: "payablePrices",
        },
        {
          title: "优惠金额",
          props: "preferentialPrices",
        },
        {
          title: "实付金额",
          props: "totalPrices",
        },
        {
          title: "所属公司",
          props: "companyName",
          width: 120,
        },
        {
          title: "线路",
          width: 100,
          SpecialJudgment: (res) => {
            return res.startStation + `-` + res.endStation;
          },
        },
        {
          title: "发车时间",
          width: 140,
          SpecialJudgment: (res) => {
            let str = res.departureDate;
            if(res.shiftType === 1){
              str += ` ${res.startDepartureTime}-${res.endDepartureTime}`;
            }else{
              str += ` ${res.startDepartureTime}`
            }
            return str;
          },
        },
        {
          title: "上车点",
          props: "startAddress",
        },
        {
          title: "下车点",
          props: "endAddress",
        },
        {
          title: "下单时间",
          width: 170,
          props: "createTime",
        },
        {
          title: "订单状态",
          props: "orderStatus",
          SpecialJudgment: (res) => {
            return this.orderStatusTextFun(
              this.orderStatusList.find((t) => t.value == res).label
            );
          },
        },
        {
          title: "退(改)优惠金额",
          props: "refundTicketPreferentialPrices",
          width: 130,
        },
        {
          title: "退票金额",
          props: "refundFee",
        },
        {
          title: "退票手续费",
          width: "100",
          props: "poundage",
        },
        {
          title: "最后退票时间",
          props: "refundTime",
          width: 110,
        },
        {
          title: "改签退回金额",
          props: "changedRefundPrices",
          width: 120,
        },
        {
          title: "改签手续费",
          props: "changedServicePrices",
          width: 100,
        },
        {
          title: "最后改签时间",
          props: "changedTime",
          width: 120,
        },
      ],
      tableData2: [],
      titleName2: [
        {
          title: "订单编号",
          props: "orderId",
        },
        {
          title: "票号",
          props: "childOrderId",
        },
        {
          title: "退款时间",
          props: "createTime",
        },
        {
          title: "可退金额",
          props: "canRefundPrices",
        },
        {
          title: "实退金额",
          props: "actualRefundPrices",
        },
        {
          title: "剩余可退金额",
          props: "residualCanRefundPrices",
        },
        {
          title: "售后退款优惠金额",
          props: "refundTicketPreferentialPrices",
        },
        {
          title: "下单人",
          props: "passengerName",
        },
        {
          title: "联系电话",
          props: "passengerPhone",
        },
        {
          title: "操作人",
          props: "operatorName",
        },
        {
          title: "退款原因",
          props: "refundRemark",
        },
      ],
      orderStatusList: [
        { value: 10, label: "待支付" },
        { value: 20, label: "待配车" },
        { value: 30, label: "已配车（待派单）" },
        { value: 40, label: "已派单（待接驾）" },
        { value: 50, label: "司机申请取消接驾" },
        { value: 60, label: "已接驾" },
        { value: 70, label: "已完成" },
        { value: 80, label: "取消接驾" },
        { value: 110, label: "订单取消" },
        { value: 120, label: "已退票" },
        { value: 130, label: "已改签" },
      ],
      total: 0,
      total2: 0,
      tableDataInner: [],
      titleNameInner: [
        {
          title: "票号",
          props: "id",
          width: "200",
        },
        {
          title: "乘客类型",
          props: "segment",
          SpecialJudgment: (res) => {
            return res == 0 ? "儿童" : "成人";
          },
        },
        {
          title: "乘客姓名",
          props: "passengerName",
        },
        {
          title: "身份证号码",
          width: 120,
          props: "idCardNo",
          SpecialJudgment: (res) => {
            return res == "匿名用户"
              ? res
              : decryptDes(res).replace(/^(.{4})(?:\d+)(.{3})$/, "$1******$2");
          },
        },
        {
          title: "订单状态",
          props: "orderStatus",
          SpecialJudgment: (res) => {
            return this.childOrderStatusList.find((t) => t.value == res).label;
          },
        },
        {
          title: "可退金额",
          props: "refundableAmountPrices",
          // SpecialJudgment: (res) => {
          //   //实际支付金额totalPrices单张
          //   //根据子订单状态判断：20已支付-payablePrices(应付金额)、90+100已退票-refundTicketServicePrices(退票手续费)
          //   return res.orderStatus === 20
          //     ? res.totalPrices
          //     : res.orderStatus === 90 || res.orderStatus === 100
          //     ? res.refundTicketServicePrices
          //     : "-";//refundableAmountPrices
          // },
        },
      ],
      charterPopupShow: false,
      refundTotal: 0,
      refundableAmountPrices: 0,
      ReasonForRefund: "",
      childOrderStatusList: [
        { value: 20, label: "已支付" },
        { value: 90, label: "用户已退票" },
        { value: 95, label: "退款中" },
        { value: 100, label: "后台已退票" },
        { value: 130, label: "已改签" },
        { value: null, label: "" },
      ],
      cancelTicketObj: {},
      openTicket: false,
      emptyText: "暂无数据",
      loading: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.computeHeight();
      window.addEventListener("resize", this.computeHeight, false);
    });
  },
  methods: {
    handleClick() {
      this.computeHeight();
      if (this.activeName == "second") {
        this.form2 = {
          orderId: "",
          passengerPhone: "",
          passengerName: "",
          pageSize: 10,
          currentPage: 1,
          startRefundDate: "",
          endRefundDate: "",
        };
        this.getHistoryList2();
      }
    },
    computeHeight() {
      const wholeHeight = this.$refs.afterSaleRefund.clientHeight;
      if (this.activeName == "first") {
        this.TableHeight = wholeHeight - 240 + "px";
      } else {
        this.TableHeight2 = wholeHeight - 240 + "px";
      }
    },
    selectTime(val) {
      if (val) {
        this.form2.endRefundDate = val[1];
        this.form2.startRefundDate = val[0];
      } else {
        this.form2.endRefundDate = null;
        this.form2.startRefundDate = null;
      }
    },
    onSearch() {
      this.form.currentPage = 1;
      if (this.form.orderId || this.form.passengerPhone || this.form.orderPhone)
        this.getHistoryList();
      else this.$message.warning("请先输入查询条件");
    },
    onSearch2() {
      this.form2.currentPage = 1;
      this.getHistoryList2();
    },
    onExport() {
      exportAfterSalesRefundDetail(this.form2).then((res) => {
        const blob = new Blob([res], { type: "application/vnd.ms-excel" });
        const fileName = "售后退款明细 " + this.GMTToStr(new Date()) + ".xlsx"; //文件名
        const linkNode = document.createElement("a");

        linkNode.download = fileName; //a标签的download属性规定下载文件的名称
        linkNode.style.display = "none";
        linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
        document.body.appendChild(linkNode);
        linkNode.click(); //模拟在按钮上的一次鼠标单击

        URL.revokeObjectURL(linkNode.href); // 释放URL 对象
        document.body.removeChild(linkNode);
      });
    },
    closeFun() {
      this.tablePopupShow = false;
    },
    /**
     * @description 售后退款
     */
    RefundTicket(row) {
      const id = row.orderId || row.intercityOrderId;
      referOrderDetialAPI(id).then((res) => {
        if (res.code === "SUCCESS") {
          this.tableDataInner = res.data;
          this.charterPopupShow = true;
        }
      });
    },
    charterPopupConfirmFun() {
      if (!this.refundTotal) {
        this.$message({
          type: "error",
          showClose: true,
          message: "请选择退票订单！",
        });
        return;
      }
      if (this.refundableAmountPrices > this.refundTotal) {
        this.$message.error("售后退款金额不能大于可退金额！");
        return;
      }
      if (this.refundableAmountPrices === "") {
        this.$message.error("请输入退款金额！");
        return;
      }
      if (!/^([1-9]|0)*(.\d{1,2})?$/.test(this.refundableAmountPrices)) {
        this.$message.error("请输入正确的退款金额！");
        return;
      }
      this.loading = true;
      refundedChildOrder({
        childOrderId: this.cancelTicketObj.ids,
        refundFee: this.refundableAmountPrices,
        refundReason: this.ReasonForRefund,
      }).then((res) => {
        if (res.code === "SUCCESS") {
          this.$message.success("退票成功!");
          this.loading = false;
          this.charterPopupClosePopupFun();
          this.getHistoryList();
        } else {
          this.$message.error(res.msg);
          this.loading = false;
        }
      }).catch(err=>{
        this.loading = false;
      });
    },
    charterPopupClosePopupFun() {
      this.refundTotal = 0;
      this.ticketPrice = 0;
      this.refundableAmountPrices = 0;
      this.ReasonForRefund = "";
      this.charterPopupShow = false;
      this.loading = false;
      this.cancelTicketObj = {};
    },
    /**
     * 根据选择的子订单自动计算可退金额，已完成订单可退金额=乘客实付金额totalPrices，已退票订单可退金额=退票手续费refundTicketServicePrices
     * refundTotal 可退金额  refundableAmountPrices输入退票金额
     */
    select(selection) {
      this.ticketPrice = 0;
      this.cancelTicketObj.ids = [];
      if (selection.length) {
        selection.forEach((e) => {
          // if (e.orderStatus === 20) {
          //   this.ticketPrice = e.totalPrices; //实际支付金额
          // } else if (e.orderStatus === 90 || e.orderStatus === 100) {
          //   this.ticketPrice = e.refundTicketServicePrices;
          // }
          this.ticketPrice = e.refundableAmountPrices;
          this.cancelTicketObj.ids = e.id; //单选\
          isAlreadyInvoicedAPI(e.id).then((res) => {
            if (res.code == "SUCCESS") {
              this.openTicket = res.data ? true : false;
            }
          });
        });
        this.refundTotal = this.ticketPrice;
      } else {
        this.ticketPrice = 0;
        this.refundTotal = 0;
      }
    },
    // 当前页码改变
    onCurrentChange(page) {
      if (this.activeName == "first") {
        this.form.currentPage = page;
        this.getHistoryList();
      } else {
        this.form2.currentPage = page;
        this.getHistoryList2();
      }
    },
    // 当前条数改变
    onSizeChange(size) {
      if (this.activeName == "first") {
        this.form.pageSize = size;
        this.form.currentPage = 1;
        this.getHistoryList();
      } else {
        this.form2.pageSize = size;
        this.form2.currentPage = 1;
        this.getHistoryList2();
      }
    },
    orderStatusTextFun(text) {
      if (text == "后台已退票") {
        return "已退票";
      } else {
        return text;
      }
    },
    //历史调度
    getHistoryList() {
      /**
       * 只查询当前时间近2天的数据
       */
      const data = {
        ...this.form,
        departureEndDateTime: `${formatDay(new Date())} 23:59:59`,
        departureStartDateTime: `${formatDay(
          new Date().getTime() - 24 * 60 * 60 * 1000
        )} 00:00:00`,
      };
      queryScheduleHistoryList(data).then((res) => {
        if (res.code === "SUCCESS") {
          this.tableData = res.data.list;
          this.total = res.data.total;
          if (this.tableData.length == 0) {
            this.emptyText = "未查询到符合条件的订单";
            this.$message.error("未查询到符合条件的订单");
          }
        } else {
          this.$message.error(res.msg);
          this.tableData = [];
        }
      });
    },
    //历史调度
    getHistoryList2() {
      queryAfterSalesRefundDetailPage(this.form2).then((res) => {
        if (res.code === "SUCCESS") {
          this.tableData2 = res.data.list;
          this.total2 = res.data.total;
        } else {
          this.$message.error(res.msg);
          this.tableData2 = [];
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.after-sale-refund-page {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 20px;
  box-sizing: border-box;
  background: #ffffff;
  .code-time-range {
    .el-date-editor {
      width: 13vw !important;
      ::v-deep .el-range-separator {
        line-height: 24px;
      }
    }
  }
  .Table {
    margin-top: 16px;
  }
  .explain {
    color: #666666;
    font-size: 14px;
    background-color: #e4ecff;
    border-radius: 6px;
    padding: 16px;
    box-sizing: border-box;
    margin-bottom: 16px;
    header {
      font-weight: bold;
      .el-icon-warning {
        color: #336ffe;
        font-size: 17px;
      }
    }
    ol {
      margin-bottom: 0;
      li {
        margin-bottom: 8px;
      }
    }
  }
}
.feeBox {
  display: flex;
  margin: 40px 0;
  justify-content: space-between;

  p {
    padding-bottom: 10px;
    font-size: 14px;
  }

  label {
    color: red;
    font-size: 14px;
  }

  /deep/.el-input__inner {
    width: 300px;
  }
}
.reasonBox {
  margin: 40px 0;

  p {
    padding-bottom: 10px;
    font-size: 14px;
  }

  /deep/.el-input__inner {
    width: 300px;
  }
}
</style>
